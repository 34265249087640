<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/ex_c.png" alt="" width="28">
        <h2>{{ state == 1 ? '编辑' : '新增' }}课题</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="contentBody">
      <el-form ref="task" size="small" :rules="rules" :model="task" class="addForm" label-suffix=":"
               label-width="100px">
        <h3 class="tit">基本信息</h3>
        <el-form-item label="课题编号" prop="number">
          <el-input v-model="task.number" clearable placeholder="请输入课题编号" class="smallWidth"></el-input>
        </el-form-item>
        <div class="flex head">
          <el-form-item label="负责人" prop="director">
            <el-input v-model="task.detail.director" clearable placeholder="请输入负责人" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="负责人电话" prop="detail.contact">
            <el-input v-model="task.detail.contact" clearable placeholder="请输入负责人电话" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="负责人邮箱" prop="detail.email">
            <el-input v-model="task.detail.email" clearable placeholder="请输入负责人邮箱" class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="课题名称" prop="name">
          <el-input v-model="task.name" clearable placeholder="请输入课题名称"></el-input>
        </el-form-item>
        <el-form-item label="课题概要" prop="summary">
          <el-input v-model="task.detail.summary" placeholder="请输入课题概要" type="textarea" rows="3"></el-input>
        </el-form-item>
        <h3 class="tit">合笼成员</h3>
        <div class="flex head">
          <el-form-item label="负责人">
            <el-select
                class="smallWidth" value-key="userId"
                v-model="task.cagePrincipalName"
                filterable clearable
                remote
                placeholder="请输入负责人姓名"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="((val)=>{handleUser(val, 1)})"
            >
              <el-option
                  v-for="item in userSelect"
                  :key="item.userId"
                  :label="item.nickname"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人电话" prop="principalPhone">
            <el-input v-model="task.cagePrincipalPhone" clearable placeholder="请输入负责人电话" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="负责人邮箱" prop="principalEmail">
            <el-input v-model="task.cagePrincipalEmail" clearable placeholder="请输入负责人邮箱" class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <el-table
            :data="task.memberList" border
            :row-class-name="tableRowClassName"
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
        >
          <el-table-column label="项目组成员姓名" width="180">
            <template slot-scope="scope">
              <el-select
                  v-if="scope.$index==0"
                  value-key="userId"
                  v-model="projectTeam.name"
                  filterable clearable
                  remote
                  placeholder="请输入负责人姓名"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  @change="((val)=>{handleUser(val, 2)})">
                <el-option
                    v-for="item in userSelect"
                    :key="item.userId"
                    :label="item.nickname"
                    :value="item">
                </el-option>
              </el-select>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-select
                      size="small" value-key="userId"
                      v-model="scope.row.name"
                      filterable clearable
                      remote
                      placeholder="请输入项目组成员姓名"
                      :remote-method="remoteMethod"
                      :loading="loading"
                      @change="((val)=>{handleUserTab(scope.row,val)})"
                  >
                    <el-option
                        v-for="item in userSelect"
                        :key="item.userId"
                        :label="item.nickname"
                        :value="item">
                    </el-option>
                  </el-select>
                </template>
                <span v-else>{{ scope.row.name }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="相关专业证书编号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="projectTeam.certNum" size="small" v-if="scope.$index==0"
                        placeholder="请输入成员专业证书编号"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入成员专业证书编号" v-model="scope.row.certNum"></el-input>
                </template>
                <span v-else>{{ scope.row.certNum }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="工作内容" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="projectTeam.jobContent" size="small" v-if="scope.$index==0"
                        placeholder="请输入工作内容"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入工作内容" v-model="scope.row.jobContent"></el-input>
                </template>
                <span v-else>{{ scope.row.jobContent }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="电话">
            <template slot-scope="scope">
              <el-input v-model="projectTeam.phone" size="small" v-if="scope.$index==0"
                        placeholder="请输入成员电话"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入成员电话" v-model="scope.row.phone"></el-input>
                </template>
                <span v-else>{{ scope.row.phone }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Email" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="projectTeam.email" size="small" v-if="scope.$index==0"
                        placeholder="请输入成员Email"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入成员Email" v-model="scope.row.email"></el-input>
                </template>
                <span v-else>{{ scope.row.email }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div v-if="scope.$index==0" class="addColor">
                <el-button type="text" @click="add(0)">添加</el-button>
                <el-button type="text" @click="reset(0)">重置</el-button>
              </div>
              <div v-else class="editColor">
                <el-button type="text" @click="edit(scope.row,scope.$index,0)">{{ scope.row.show ? '保存' : "修改" }}
                </el-button>
                <el-button type="text" @click="copy(scope.row,scope.$index,0)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.$index,0)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="btn">
          <el-button size="small" type="primary" @click="editAll(0)">批量编辑</el-button>
          <el-button size="small" type="primary" @click="submit(0)">批量保存</el-button>
        </div>
        <h3 class="tit">课题动物</h3>
        <el-form-item label="来源于实验室">
          <el-radio-group v-model="task.isLabAni" class="radioGroup smallWidth" @change="changeVal">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="flex head">
          <el-form-item label="来源">
            <template v-if="task.isLabAni==0">
              <el-select
                  class="smallWidth"
                  v-model="task.aniOrder.aniSupply.supplier"
                  allow-create
                  filterable
                  default-first-option
                  v-el-select-loadmore="loadAll"
                  placeholder="请选择来源">
                <el-option
                    v-for="item in restaurants"
                    :label="item.supplier"
                    :value="item.supplier"
                    :key="item.id"></el-option>
              </el-select>
            </template>
            <el-input v-else v-model="task.aniOrder.aniSupply.supplier" clearable placeholder="请输入来源" readonly
                      class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="许可证" prop="prodCert">
            <el-input v-model="task.aniOrder.aniSupply.prodCert" clearable placeholder="请输入许可证"
                      class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <div class="flex head">
          <el-form-item label="动物品系" prop="strain">
            <el-input v-model="task.strain" clearable placeholder="请输入动物品系" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="笼舍数量" prop="cageNum">
            <el-input v-model="task.cageNum" type="number" :min="0" placeholder="请输入需要笼舍数量"
                      class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="等级">
          <el-radio-group v-model="task.aniOrder.grade" class="radioGroup">
            <el-radio :label="0">普通级</el-radio>
            <el-radio :label="1">SPF</el-radio>
            <el-radio :label="2">清洁级</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="特殊基因合笼">
          <el-radio-group v-model="task.aniOrder.isGene" class="radioGroup">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="基因类型" class="geneTypes" v-if="task.aniOrder.isGene==1">
          <div class="modeFor" v-for="(obj,ind) in geneTypes" :key="ind">
            <el-input :key="ind" v-model="obj.title" clearable :readonly="obj.readonly"
                      :placeholder='"请输入基因类型"+(ind+1)'
                      class="smallWidth"></el-input>
            <el-button size="small" plain class="delIns" icon="el-icon-close" @click="delType(ind)"></el-button>
          </div>
          <div>
            <el-button icon="el-icon-plus" class="addInstru" @click="addType"></el-button>
            <el-button class="geneTypeSubmit" type="primary" @click="submitType" :disabled="disabled">确认</el-button>
          </div>
        </el-form-item>
        <template>
          <el-table
              :data="task.aniOrder.aniOrderDetailList" border
              :row-class-name="tableRowClassName"
              class="tableMember"
              :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          >
            <el-table-column
                v-if="animal.typeList  &&  animal.typeList.length>0"
                label="基因类型">
              <el-table-column v-for="(obj,ind) in animal.typeList" :key="ind"
                               :label="obj.title"
                               min-width="90" show-overflow-tooltip>
                <template slot-scope="scope">
                  <template v-if="scope.$index===0">
                    <el-input v-model="obj.type" size="small"
                              :placeholder='"请输入基因类型"+(ind+1)'></el-input>
                  </template>
                  <template v-else>
                    <template v-if="scope.row.show">
                      <el-input size="small" placeholder="请输入基因类型"
                                v-model="scope.row.typeList[ind].type"/>
                    </template>
                    <template v-else>
                      {{ scope.row.typeList[ind].type }}
                    </template>
                  </template>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="性别" width="120">
              <template slot-scope="scope">
                <el-select v-model="animal.gender" v-if="scope.$index==0" size="small"
                           placeholder="请选择性别">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <template v-else>
                  <template v-if="scope.row.show">
                    <el-select v-model="scope.row.gender" size="small" placeholder="请选择性别">
                      <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </template>
                  <span v-else>{{ scope.row.gender == 0 ? '♀' : '♂' }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="数量">
              <template slot-scope="scope">
                <el-input v-model="animal.count" size="small" v-if="scope.$index==0"
                          placeholder="请输入数量"></el-input>
                <template v-else>
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入数量" v-model="scope.row.count"></el-input>
                  </template>
                  <span v-else>{{ scope.row.count }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="体重(g)">
              <template slot-scope="scope">
                <el-input v-model="animal.weight" size="small" v-if="scope.$index==0"
                          placeholder="请输入体重(g)"></el-input>
                <template v-else>
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入体重(g)" v-model="scope.row.weight"></el-input>
                  </template>
                  <span v-else>{{ scope.row.weight }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="周龄">
              <template slot-scope="scope">
                <el-input v-model="animal.weekDays" size="small" v-if="scope.$index==0"
                          placeholder="请输入周龄"></el-input>
                <template v-else>
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入周龄" v-model="scope.row.weekDays"></el-input>
                  </template>
                  <span v-else>{{ scope.row.weekDays }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="140">
              <template slot-scope="scope">
                <div v-if="scope.$index==0" class="addColor">
                  <el-button type="text" @click="add(1)">添加</el-button>
                  <el-button type="text" @click="reset(1)">重置</el-button>
                </div>
                <div v-else class="editColor">
                  <el-button type="text" @click="edit(scope.row,scope.$index,1)">{{ scope.row.show ? '保存' : "修改" }}
                  </el-button>
                  <el-button type="text" @click="copy(scope.row,scope.$index,1)">复制</el-button>
                  <el-button type="text" @click="deleteA(scope.$index,1)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="btn">
            <el-button size="small" type="primary" @click="editAll(1)">批量编辑</el-button>
            <el-button size="small" type="primary" @click="submit(1)">批量保存</el-button>
          </div>
        </template>
        <h3 class="tit">合笼时间</h3>
        <div class="flex head">
          <el-form-item label="计划开始时间">
            <el-date-picker
                size="small"
                v-model="task.startTime"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <h3 class="tit">合笼备注</h3>
        <el-form-item label="合笼备注" prop="remark">
          <el-input v-model="task.detail.remark" placeholder="请输入合笼备注" type="textarea" rows="3"></el-input>
        </el-form-item>
        <h3 class="tit">方案是否审批</h3>
        <el-form-item label="是否审批">
          <el-radio-group v-model="task.isNotApproval" class="radioGroup">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审批人员" v-if="task.isNotApproval==0">
          <el-radio-group v-model="task.detail.apprNickname" class="radioGroup">
            <el-radio :label="item.nickname" v-for="(item,ind) in list" @change="getAppId(item.userId)"
                      :key="item.userId">
              {{ item.nickname }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="addLabPlan('task',1)">提交</el-button>
          <el-button plain type="primary" size="small" @click="addLabPlan('task',0)">保存</el-button>
        </div>
      </el-form>
    </div>

    <el-dialog :close-on-click-modal="false"
        :show-close="false"
        class="commonDialog flex" center
        top="0"
        :visible.sync="dialogVisible"
        width="260px">
      <img src="../../../assets/img/success.png" alt="" width="52px">
      <h2>新建成功</h2>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="$router.push({path:'/sys/taskList'})">返回列表页</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "addTask",
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        );
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    };
    /*let checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    };*/
    return {
      state: this.$route.query.state,
      id: this.$route.query.id,
      task: {
        number: "",
        name: "",
        // 方案成员
        memberList: [],
        cagePrincipalName: "",
        cagePrincipalPhone: "",
        cagePrincipalEmail: "",
        cagePrincipalId: null,
        strain: "",
        startTime: new Date(),
        // 审批人
        isNotApproval: 0,
        isLabAni: 0,
        // 摘要描述 负责人（基本信息） 审批
        detail: {
          apprNickname: "",
          apprId: null,
          contact: "",
          director: "",
          email: "",
          remark: "",
          summary: "",
        },
        cageNum: null,
        // 课题动物
        aniOrder: {
          grade: 0,
          isGene: 0,
          geneKeys: "",
          // 基因类型列表
          aniOrderDetailList: [],
          // 供应商
          aniSupply: {
            prodCert: "",
            supplier: "",
          },
        },
        teamUserIds: "",
        dirNickname: "",
      },
      // 基因类型
      geneTypes: [],
      disabled: true,
      // 方案成员添加
      projectTeam: {
        userId: "",
        name: "",
        certNum: "",
        jobContent: "",
        phone: "",
        email: "",
        isPrincipal: 0
      },
      rules: {
        number: [
          {required: true, message: '课题编号不能为空', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '课题名称不能为空', trigger: 'blur'}
        ],
        cageNum: [
          {required: true, message: '笼舍数量不能为空', trigger: 'blur'}
        ],
        "detail.contact": [{validator: checkPhone, trigger: 'blur'}],
        // "detail.email": [{validator: checkEmail, trigger: 'blur'}],
      },
      // 用户成员
      list: [],
      loading: false,
      userSelect: [],
      // 来源
      restaurants: [],
      formData: {   //下拉参数
        pageIndex: 1,
        pageSize: 20
      },
      supplyList: [],
      // 动物基因类型
      animal: {
        typeList: [],
        gene: "",
        gender: null,
        count: "",
        weekDays: "",
        weight: ""
      },
      options: [{
        value: 0,
        label: '雌性'
      }, {
        label: '雄性',
        value: 1
      }],
      dialogVisible: false,
    }
  },
  created() {
    // 项目成员
    let top = {
      userId: "",
      name: "",
      certNum: "",
      jobContent: "",
      phone: "",
      email: "",
      isPrincipal: 0
    }
    this.task.memberList.unshift(top)
    this.task.memberList.map(i => {
      i.show = false
      return i
    })
    // 基因类型
    let topA = {
      typeList: [],
      gene: "",
      gender: null,
      count: "",
      weekDays: "",
      weight: ""
    }
    this.task.aniOrder.aniOrderDetailList.unshift(topA)
    this.task.aniOrder.aniOrderDetailList.map(i => {
      i.show = false
      return i
    })


  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    this.$get("/oauth/user/personal/" + this.user.companyId).then((res) => {
      if (res.status == 200) {
        this.list = res.data
      }
    })
    this.getSupply(this.formData);
    if (this.state == 1) {
      this.getEditDetail()
    }
  },
  methods: {
    // 负责人筛选
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.userSelect = this.list.filter(item => {
            return item.nickname.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.userSelect = [];
      }
    },
    // 成员
    handleUser(val, ind) {
      if (ind == 1) {
        this.task.cagePrincipalId = val.userId
        this.task.cagePrincipalPhone = val.mobile
        this.task.cagePrincipalEmail = val.email
      } else {
        this.projectTeam.userId = val.userId
        this.projectTeam.name = val.nickname
        this.projectTeam.phone = val.mobile
        this.projectTeam.email = val.email
      }
    },
    handleUserTab(row, val) {
      row.userId = val.userId
      row.name = val.nickname
      row.phone = val.mobile
      row.email = val.email
    },
    changeVal(val) {
      this.task.aniOrder.aniSupply.supplier = ""
      if (val == 1) {
        this.task.aniOrder.aniSupply.supplier = "实验室"
      }
    },
    // 添加删除基因类型
    addType() {
      this.geneTypes.push({
        title: "",
        type: "",
        readonly: false
      })
      this.disabled = false
    },
    delType(index) {
      this.geneTypes.splice(index, 1)
      this.animal.typeList.splice(index, 1)
    },
    // 添加基因类型
    submitType() {
      const vm = this;
      this.animal.typeList = this.deepClone4(this.geneTypes)
      // 遍历已有的数据，判断当前已有数据的typeList数组长度，不够补空对象
      this.task.aniOrder.aniOrderDetailList.forEach((item, index) => {
        if (!item.typeList || item.typeList.length === 0) {//item.typeList不存在 或者 item.typeList=[]
          let arr = []
          for (let i = 0; i < vm.animal.typeList.length; i++) {
            arr.push(Object.assign({}, vm.animal.typeList[i]))
          }
          vm.$set(vm.task.aniOrder.aniOrderDetailList[index], 'typeList', arr)
        } else if (item.typeList.length !== vm.animal.typeList.length) {// item.typeList 长度比 this.animal.typeList 小
          for (let i = item.typeList.length - 1; i < vm.animal.typeList.length - 1; i++) {
            item.typeList.push({
              title: String(vm.animal.typeList[i].title),
              type: "",
              readonly: false
            })
          }
        }
      })
      let tit = this.geneTypes.map(o => o.title)
      this.task.aniOrder.geneKeys = tit.join(",");
      console.log( this.task.aniOrder.geneKeys)

      this.disabled = true
    },
    // 设置第一行样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'formAdd';
      }
      return '';
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
    // 0添加项目成员 1添加动物
    add(ind) {
      if (ind == 0) {
        if ($.trim(this.projectTeam.name).length === 0) {
          this.$message.warning("成员姓名不能为空");
          return;
        }
        // if ($.trim(this.projectTeam.certNum).length === 0) {
        //   this.$message.warning("专业证书编号不能为空");
        //   return;
        // }
        if ($.trim(this.projectTeam.jobContent).length === 0) {
          this.$message.warning("工作内容不能为空");
          return;
        }
        if ($.trim(this.projectTeam.phone).length === 0) {
          this.$message.warning("电话号码不能为空");
          return;
        }
        this.task.memberList.push(this.projectTeam)
        this.projectTeam = {
          userId: "",
          name: "",
          certNum: "",
          jobContent: "",
          phone: "",
          email: "",
          isPrincipal: 0,
        }
      } else {
        if ($.trim(this.animal.count).length === 0) {
          this.$message.warning("数量不能为空");
          return;
        }
        if ($.trim(this.animal.weight).length === 0) {
          this.$message.warning("体重不能为空");
          return;
        }
        if ($.trim(this.animal.weekDays).length === 0) {
          this.$message.warning("周龄不能为空");
          return;
        }

        let result = this.deepClone4(this.animal)
        this.task.aniOrder.aniOrderDetailList.push(result)

        this.task.aniOrder.aniOrderDetailList.forEach((item, index) => {
          item.gene = JSON.stringify(item.typeList)
        })

        this.animal.typeList.forEach(item => {
          item.type = ""
        })

        this.animal.id = ""
        this.animal.gene = ""
        this.animal.gender = null
        this.animal.count = ""
        this.animal.weekDays = ""
        this.animal.weight = ""
      }
    },
    // 重置
    reset(ind) {
      if (ind == 0) {
        this.projectTeam = {
          userId: "",
          name: "",
          certNum: "",
          jobContent: "",
          phone: "",
          email: "",
          isPrincipal: 0
        }
      } else {
        this.animal.typeList.forEach(item => {
          item.type = ""
        })
        this.animal.gene = ""
        this.animal.gender = null
        this.animal.count = ""
        this.animal.weekDays = ""
        this.animal.weight = ""
      }
    },
    // 编辑
    edit(row, index, ind) {
      row.show = !row.show
      if (ind === 0) {
        this.$set(this.task.memberList, index, row)
      } else {
        this.task.aniOrder.aniOrderDetailList.forEach((item, index) => {
          item.gene = JSON.stringify(item.typeList)
        })
        this.$set(this.task.aniOrder.aniOrderDetailList, index, row)
      }
    },
    // 复制
    copy(val, index, ind) {
      if (ind == 0) {
        this.task.memberList.splice(index, 0, JSON.parse(JSON.stringify(val)))
      } else {
        this.task.aniOrder.aniOrderDetailList.splice(index, 0, JSON.parse(JSON.stringify(val)))
      }
    },
    // 删除
    deleteA(index, ind) {
      if (ind == 0) {
        this.task.memberList.splice(index, 1)
      } else {
        this.task.aniOrder.aniOrderDetailList.splice(index, 1)
      }
    },
    // 批量编辑
    editAll(ind) {
      if (ind == 0) {
        this.task.memberList.map((i, index) => {
          i.show = true
          this.$set(this.task.memberList, index, i)
        })
      } else {
        this.task.aniOrder.aniOrderDetailList.map((i, index) => {
          i.show = true
          this.$set(this.task.aniOrder.aniOrderDetailList, index, i)
        })
      }

    },
    // 批量保存
    submit(ind) {
      if (ind == 0) {
        this.task.memberList.map((i, index) => {
          i.show = false
          this.$set(this.task.memberList, index, i)
        })
      } else {
        this.task.aniOrder.aniOrderDetailList.map((i, index) => {
          i.show = false
          this.$set(this.task.aniOrder.aniOrderDetailList, index, i)
        })
      }
    },
    // 来源
    loadAll() {
      this.formData.pageIndex++;
      this.getSupply(this.formData);
    },
    getSupply() {
      let param = {
        pageSize: this.formData.pageSize,
        pageNum: this.formData.pageIndex
      };
      this.$get("/supply/byPage", param).then(res => {
        this.restaurants = [...this.restaurants, ...res.data.records]
      }).catch(() => {
        this.$message.error('查询失败');
      })
    },
    // 获取编辑详情
    getEditDetail() {
      this.$get("/subject/detail/" + this.id).then((res) => {
        if (res.status == 200) {
          let newStr = {
            id: res.data.id,
            number: res.data.number,
            name: res.data.name,
            // 方案成员
            memberList: [],
            cagePrincipalName: "",
            cagePrincipalPhone: "",
            cagePrincipalEmail: "",
            cagePrincipalId: null,
            strain: res.data.strain,
            startTime: new Date(),
            // 审批人
            isNotApproval: res.data.isNotApproval,
            isLabAni: res.data.isLabAni,
            // 摘要描述 负责人（基本信息） 审批
            detail: {
              subId: res.data.id,
              apprNickname: res.data.detail.apprNickname,
              apprId: res.data.detail.apprId,
              contact: res.data.detail.contact,
              director: res.data.detail.director,
              email: res.data.detail.email,
              remark: res.data.detail.remark,
              summary: res.data.detail.summary,
            },
            cageNum: res.data.cageNum,
            // 课题动物
            aniOrder: {
              grade: res.data.aniOrder.grade,
              isGene: res.data.aniOrder.isGene,
              geneKeys: res.data.aniOrder.geneKeys,
              // 基因类型列表
              aniOrderDetailList: [],
              // 供应商
              aniSupply: {
                prodCert: res.data.aniOrder.aniSupply.supplier,
                supplier: res.data.aniOrder.aniSupply.supplier,
              },
            },
            teamUserIds: res.data.teamUserIds,
            dirNickname: res.data.dirNickname,
          }
          // 成员
          newStr.cagePrincipalName = res.data.dirNickname;
          res.data.memberList.forEach((item, index) => {
            if (item.isPrincipal == 1) {
              newStr.cagePrincipalPhone = item.phone
              newStr.cagePrincipalEmail = item.email
              newStr.cagePrincipalId = item.userId
              newStr.memberList.splice(index, 1)
            } else {
              newStr.memberList.push(item)
            }
          })

          newStr.memberList = this.task.memberList.concat(newStr.memberList)

          // 动物列表
          newStr.aniOrder.aniOrderDetailList = res.data.aniOrder.aniOrderDetailList
          newStr.aniOrder.aniOrderDetailList = this.task.aniOrder.aniOrderDetailList.concat(newStr.aniOrder.aniOrderDetailList)


          // 基因类型回填
          if (newStr.aniOrder.isGene == 1) {
            newStr.aniOrder.aniOrderDetailList.forEach(obj => {
              if (obj.gene) {
                obj.typeList = JSON.parse(obj.gene)
              }
            })
            let arr = newStr.aniOrder.geneKeys.split(',')
            // console.log(arr)
            arr.forEach(item => {
              this.animal.typeList.push({
                title: item,
                type: ""
              })
              this.geneTypes.push({
                title: item,
                type: "",
                readonly: true
              })
            })
          }
          this.task = newStr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取审批人id
    getAppId(id) {
      this.task.detail.apprId = id
    },
    addLabPlan(task, status) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
          if ($.trim(this.task.detail.email).length !== 0&&!mailReg.test(this.task.detail.email)) {
            this.$message.warning("请填写正确的邮箱格式");
          }

          this.task.status = status
          this.task.isProd = 1
          this.task.memberList.splice(0, 1)
          this.task.aniOrder.applyCageNum = this.task.cageNum
          if(this.task.id){
            this.task.aniOrder.subId = this.task.id
          }
          //合笼成员
          let principal = {
            name: this.task.cagePrincipalName.nickname,
            phone: this.task.cagePrincipalPhone,
            email: this.task.cagePrincipalEmail,
            userId: this.task.cagePrincipalId,
            isPrincipal: 1
          }
          this.task.memberList.push(principal);

          let arr = this.task.memberList.map(o => o.userId)
          this.task.teamUserIds = arr.join(",");
          this.task.dirNickname = this.task.cagePrincipalName.nickname;


          // 课题动物
          console.log(this.task.aniOrder.aniOrderDetailList)

          this.task.aniOrder.aniOrderDetailList.forEach((item, index) => {
            item.gene = JSON.stringify(item.typeList)
          })

          this.task.aniOrder.aniOrderDetailList.splice(0, 1)

          /*this.animal.typeList.forEach(item => {
            let key = item.title;
            this.task.aniOrder.geneKeys[key] = item.type;
          })*/
          // console.log(this.animal.typeList)
          // 品系
          this.task.aniOrder.strain = this.task.strain

          let url;
          if (this.state == 1) {
            // 编辑
            url = this.$putJson("/subject", this.task)
          } else {
            // 新增
            url = this.$postJson("/subject", this.task)
          }
          url.then((res) => {
            this.dialogVisible = true
          }).catch(() => {
            this.$message.error('提交失败')
          })

        } else {
          return false;
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/experiment";

/*.maxHeight {
  height: auto;
}

.contentBody {
  height: calc(100vh - 180px);
}*/
</style>
